<div class="container">
  <div class="header">
    <img src="../../../assets/logo_login_aligned.svg" class="logo">
    <div class="header-text">E-orodje za načrtovanje in vrednotenje obrokov v <br>
      vzgojno-izobraževalnih zavodih</div>
  </div>
  <div class="content">
    <div class="left-content">
      <div class="left-content-text">E-orodje PISKR je namenjeno izključno organizatorjem prehrane in vodjem kuhinj v vzgojno-izobraževalnih zavodih.</div>
      <div class="left-content-text">Za dostop nam pišite na solskilonec@nijz.si</div>
      <div class="login-form-container">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="right-content">
      <img src="../../../assets/graphics/login_preview_image.png" class="side-image">
    </div>
  </div>
</div>

<div class="footer">
  <a href="https://nijz.si/" target="_blank">
    <img src="../../../assets/logotypes/nijz_color.svg" alt="NIJZ logo" class="footer-icon">
  </a>
  <a href="https://ijs.si/" target="_blank">
    <img src="../../../assets/logotypes/ijs_color.svg" alt="IJS logo" class="footer-icon">
  </a>
  <a href="https://www.gov.si/drzavni-organi/ministrstva/ministrstvo-za-zdravje/" target="_blank">
    <img src="../../../assets/logotypes/mz_color.svg" alt="Republika Slovenija, Ministrstvo za zdravje logo" class="footer-icon">
  </a>
  <a href="https://www.solskilonec.si/" target="_blank">
    <img src="../../../assets/logotypes/lonec_color.svg" alt="Šolski lonec logo" class="footer-icon">
  </a>
  <a href="https://www.dobertekslovenija.si/" target="_blank">
    <img src="../../../assets/logotypes/dober_tek_color.svg" alt="Dober tek Slovenija logo" class="footer-icon">
  </a>
</div>