<div class="header">
  <div class="header-date-picker">
    <a class="date--prev" *ngIf="prevWeek" [routerLink]="['/preparation', prevWeek]"></a>

    <app-date-picker [dates]="allWorkdates" (selected)="navigateDate($event)" [weekNumbers]="true">
      Dobava: {{ currentDate.week() }}. teden, {{ currentDate.format('MMMM') }}
    </app-date-picker>

    <a class="date--next" *ngIf="nextWeek" [routerLink]="['/preparation', nextWeek]"></a>
  </div>
  <a class="btn-report" [href]="reportWeek()" download>Natisni<br>dobavo</a>
  <a class="btn-report" [href]="reportYear()" download>Natisni letno<br>poročilo</a>
  <app-select-button>
    <button class="btn-report">Natisni<br>recepte</button>
    <select (change)="reportRecipes($event)">
      <option disabled selected></option>
      <option [value]="wd.date" *ngFor="let wd of workdates">{{ wd.name }}</option>
    </select>
  </app-select-button>
  <!--
    <div class="day-totals">
    Skupna cena:
    <ng-container *ngIf="totalCost">{{ totalCost | number:'1.0-2' }} €</ng-container>
    <ng-container *ngIf="!totalCost">-</ng-container>
  </div>
  -->
  <app-toggle-help>
    Na osnovni načrtovanih jedilnikov in števila naročnikov na obroke, orodje pripravi seznam živil s količinami, ki so potrebne za izvedbo jedilnika posameznega dne. Organizator prehrane ali vodja kuhinje na podlagi zaloge v shrambi in potrebnih količin hrane za izvedbo jedilnika pripravi naročilo za dobavitelja.
  </app-toggle-help>
</div>

<div class="products" *ngIf="products.length > 0">
  <div class="products--header" (click)="toggleSort('name')" [ngClass]="sortCls('name')">Živila</div>
  <div class="products--header" (click)="toggleSort('eCatalog')" [ngClass]="sortCls('eCatalog')">Skupina živil</div>
  <div class="products--header" (click)="toggleSort('producer')" [ngClass]="sortCls('producer')">Proizvajalec</div>
  <div class="products--header" (click)="toggleSort('supplier')" [ngClass]="sortCls('supplier')">Dobavitelji</div>
  <div class="products--header products--header-weight" (click)="toggleSort('targetWeight')" [ngClass]="sortCls('targetWeight')">Količina</div>
  <div class="products--header">Pakiranja</div>
  <div class="products--header" (click)="toggleSort('difference')" [ngClass]="sortCls('difference')">Razlika</div>
  <div class="products--header" (click)="toggleSort('cost')" [ngClass]="sortCls('cost')">Cena</div>
  <ng-container *ngFor="let product of products">
    <div class="products--name">
      <span (click)="replacer.show(product.id)">{{ product.name }}</span>
      <button (click)="editProduct(product.id)"></button>
    </div>
    <div class="products--dunford">{{ product.eCatalog | ecatalogName }}</div>
    <div class="products--producer">{{ product.producer }}</div>
    <div class="products--supplier">{{ product.supplier }}</div>
    <div class="products--weight">{{ product.targetWeight | number:'1.0-2' }} g</div>
    <ng-container *ngIf="product.packaging">
      <div class="product--packages">
        <div class="product--package" *ngFor="let index of pkgIndex">
          <ng-container *ngIf="index < product.packaging.length && !product.packaging[index].divisible">
            <span class="package--amount">{{ product.packaging[index].amount }} g</span>
            <span class="package--times">x</span>
            <app-count [value]="product.packaging[index].count" (changed)="update(product.id, product.packaging[index].id, $event)"></app-count>
          </ng-container>
          <ng-container *ngIf="index < product.packaging.length && product.packaging[index].divisible">
            <input type="number" [value]="product.packaging[index].amount">
            <span>g</span>
          </ng-container>
        </div>
      </div>
      <div class="product--diff">{{ product.difference > 0 ? '+' : '' }}{{ product.difference | number:'1.0-2' }} g</div>
      <div class="product--cost">{{ product.cost | number:'1.2-2' }} €</div>
    </ng-container>
    <ng-container *ngIf="!product.packaging">
      <div class="product--packages">
        <div class="product--package"></div>
      </div>
      <div class="product--diff"></div>
      <div class="product--cost"></div>
    </ng-container>
  </ng-container>
</div>

<app-prep-replace #replacer (selected)="replace($event)"></app-prep-replace>
