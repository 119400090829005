import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';
import { isEmpty, memoize } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';
import {
  AllergenToParent,
  CalendarYearResponse,
  Category,
  Component,
  Dunford,
  DunfordToParent,
  MealSize,
  MenuStatus
} from './api.abstract.service';
import { concatMap, tap } from 'rxjs/operators';

const BitSet = require('bitset');

export const DunfordList = genFlatDunfordMap();

export const AllAllergens = Array.from(AllergenToParent.keys()).sort();

export function toBitset(values: number[]) {
  if (!values?.length) {
    return '0x0';
  }
  return '0x' + new BitSet(values.map(x => x - 1)).toString(16);
}

export function fromBitset(value: string): number[] {
  if (!value) {
    return [];
  }
  return new BitSet(value).toArray().map(x => x + 1);
}

export function mapMealSize(v: any): MealSize | null {
  const code = parseFloat(v);

  if ((MealSize as any)[code] !== undefined) {
    return code as MealSize;
  }

  return null;
}

export const AVAILABLE_MONTHS = [9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8];

export const getWeekdayNames = memoize(() => {
  const names = getDayjsLocaleData().weekdaysMin();
  return names.slice(1).concat(names[0]);
});

export function mapMapToArray<K, V, T>(m: Map<K, V>, cb: (v: V, k: K) => T): T[] {
  const rv: T[] = [];
  m.forEach((v, k) => rv.push(cb(v, k)));
  return rv;
}

export function mapMapValues<K, V, T>(m: Map<K, V>, cb: (v: V, k: K) => T): Map<K, T> {
  const rv = new Map<K, T>();
  m.forEach((v, k) => rv.set(k, cb(v, k)));
  return rv;
}

export function isValueOutside(value: number, min: number, max: number) {
  return value > max || min && value < min;
}

interface Nutrients {
  values: [Component, number][];

  units: [Category, number][];
}

function sumAndFactorNutrients(nutritions: Nutrients[], factor: number): [[Component, number][], [Category, number][]] {
  const values: [Component, number][] = [];
  const units: [Category, number][] = [];

  nutritions.forEach(nutrition => {
    nutrition.values.forEach(([cp, value]) => {
      const index = values.findIndex(([id]) => id === cp);
      if (index !== -1) {
        values[index][1] += value * factor;
      } else {
        values.push([cp, value * factor]);
      }
    });
    nutrition.units.forEach(([grp, value]) => {
      const index = units.findIndex(([id]) => id === grp);
      if (index !== -1) {
        units[index][1] += value * factor;
      } else {
        units.push([grp, value * factor]);
      }
    });
  });

  return [values, units];
}

export function sumNutrients(nutritions: Nutrients[]): [[Component, number][], [Category, number][]] {
  return sumAndFactorNutrients(nutritions, 1);
}

export function avgNutrients(nutritions: Nutrients[]): [[Component, number][], [Category, number][]] {
  if (nutritions.length === 0) {
    return [[], []];
  }
  return sumAndFactorNutrients(nutritions, 1 / nutritions.length);
}

export function validateFormArrayMinLength(minLength: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if ((control as FormArray).length < minLength) {
      return {
        minArrayLength: true
      };
    }
    return null;
  };
}


function genFlatDunfordMap() {
  function* codes(parent: Dunford | null) {
    for (const [c, p] of DunfordToParent) {
      if (parent === p) {
        yield c;
      }
    }
  }

  function gen(list, parent: Dunford | null = null, level = 0) {
    for (const code of codes(parent)) {
      list.push({ code, level });
      gen(list, code, level + 1);
    }
    return list;
  }

  return gen([]);
}

export function combineStatuses(statuses: MenuStatus[]): MenuStatus {
  if (statuses.length === 0) {
    return 'bare';
  }
  return statuses.reduce((c, s) => {
    if (c === 'completed' && s === 'completed') {
      return 'completed';
    }
    if (c === 'invalid' || s === 'invalid') {
      return 'invalid';
    }
    return 'bare';
  }, 'completed');
}

export class SubscriptionSink extends Subscription {

  set sink(s: Subscription) {
    this.add(s);
  }

}

export function interleave<T>(a: T[], b: T[]): T[] {
  const rv: T[] = [];

  let i = 0;
  while (i < a.length || i < b.length) {
    if (i < a.length) {
      rv.push(a[i]);
    }
    if (i < b.length) {
      rv.push(b[i]);
    }
    i++;
  }

  return rv;
}

export function getAllFormErrors(form: FormGroup | FormArray) {
  const errors = {};
  const controls: [string, AbstractControl][] = form instanceof FormArray ? form.controls.map((c, i) => [i.toString(), c]) : Object.entries(form.controls);
  controls.forEach(([name, control]) => {
    if (control instanceof FormGroup || control instanceof FormArray) {
      const childErrors = getAllFormErrors(control);
      if (!isEmpty(childErrors)) {
        errors[name] = childErrors;
      }
    } else if (control.errors) {
      errors[name] = control.errors;
    }
  });
  return errors;
}

// https://www.gs1.org/services/how-calculate-check-digit-manually
export function validateGs1(value: any) {
  const lengths = [8 /* GTIN-8 */, 12 /* GTIN-12 */, 13 /* GTIN-13 */, 14 /* GTIN-14 */, 17 /* GSIN */, 18 /* SSCC */];

  if (typeof (value) !== 'string') {
    return false;
  }

  if (!lengths.includes(value.length)) {
    return false;
  }

  let mul1: number;
  let mul2: number;

  if (value.length % 2 === 0) {
    mul1 = 3;
    mul2 = 1;
  } else {
    mul1 = 1;
    mul2 = 3;
  }

  const chars = value.split('');

  if (chars.find(c => !/\d/.test(c))) {
    return false;
  }

  let checksum = chars.slice(0, -1).map((c, i) => (i % 2 === 0 ? mul1 : mul2) * ~~c).reduce((c, v) => c + v, 0);
  checksum = (10 - (checksum % 10)) % 10;

  return checksum === ~~chars[chars.length - 1];
}

export function gs1Validator(control: AbstractControl) {
  if (!validateGs1(control.value)) {
    return {
      gs1: true,
    };
  }
  return null;
}

export function gs1ValidatorOrEmpty(control: AbstractControl) {
  if (control.value === null || control.value === undefined || control.value === '') {
    return null;
  }

  if (!validateGs1(control.value)) {
    return {
      gs1: true,
    };
  }
  return null;
}

export function basicEanValidatorOrEmpty(control: AbstractControl) {
  const allowedLengths = [6, 7, 8, 13];

  if (control.value === null || control.value === undefined || control.value === '') {
    return null;
  }

  if (!allowedLengths.includes(control.value.length)) {
    return {
      ean: true,
    };
  }

  return null;
}

window['generateGs1'] = () => {
  const lengths = [8 /* GTIN-8 */, 12 /* GTIN-12 */, 13 /* GTIN-13 */, 14 /* GTIN-14 */, 17 /* GSIN */, 18 /* SSCC */];

  let mul1: number;
  let mul2: number;

  const len = lengths[Math.floor(Math.random() * lengths.length)];
  if (len % 2 === 0) {
    mul1 = 3;
    mul2 = 1;
  } else {
    mul1 = 1;
    mul2 = 3;
  }

  let checksum = 0;
  let gs1 = '';
  for (let i = 0; i < len - 1; i++) {
    const digit = Math.floor(Math.random() * 10);
    checksum += (i % 2 === 0 ? mul1 : mul2) * digit;
    gs1 += digit;
  }
  checksum = (10 - (checksum % 10)) % 10;

  return gs1 + checksum;
};

export interface Day {
  index: number;

  date: string;

  active: boolean;

  current: boolean;

  exceptions: boolean;
}

export function mapCalendarYearToDays(response: Partial<CalendarYearResponse>, month: number): Day[] {
  const { year, workdays, exceptions } = response;
  const monthStart = dayjs(new Date(year + (month < 9 ? 1 : 0), month - 1));
  const monthEnd = monthStart.endOf('month');
  const calendarStart = monthStart.startOf('week');
  const calendarEnd = monthEnd.endOf('week');
  const days: Day[] = [];

  let timestamp = calendarStart;
  do {
    const date = timestamp.format('YYYY-MM-DD');
    days.push({
      index: timestamp.date(),
      date,
      active: workdays.includes(date),
      current: !(timestamp.isBefore(monthStart) || timestamp.isAfter(monthEnd)),
      exceptions: exceptions?.includes(date)
    });
    timestamp = timestamp.add(1, 'day');
  } while (timestamp.isBefore(calendarEnd));

  return days;
}

const getDayjsLocaleData = memoize(() => dayjs().localeData());

export function getMonthName(month: number) {
  return getDayjsLocaleData().months()[month - 1];
}

export function isLeapYear(year: number) {
  return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
}

export function daysInMonth(year: number, month: number) {
  if ([1,3,5,7,8,10,12].includes(month)) {
    return 31;
  } else if (month === 2) {
    return isLeapYear(year) ? 29 : 28;
  }
  return 30;
}

export type Color = 'cyan' | 'energy';

export const ColorToRgb = new Map<Color, string>([
  ['cyan', '#C3D8E3'],
  ['energy', '#E0B3D8'],
]);

export function guideToWidth(value: number, max: number) {
  return Math.min(100, 100 * value / (max * 1.2)) + '%';
}

export function nonZeroValidator(control: AbstractControl) {
  if (control.value) {
    return {};
  }
  return {
    zeroValue: ''
  };
}

export function serialized() {
  return function<T>(source: Observable<Observable<T>>): Observable<T> {
    return new Observable(subscriber => {
      let cnt = 0;
      source.pipe(
        tap(() => cnt++),
        concatMap(obs => obs),
        tap(() => cnt--),
      ).subscribe({
        next(value: T) {
          if(cnt === 0) {
            subscriber.next(value);
          }
        },
        error(error) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        }
      });
    });
  }
}

export function toBase64(file: File): Observable<string> {
  return new Observable(observer => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof(reader.result) === 'string') {
        observer.next(reader.result);
        observer.complete();
      } else {
        observer.error(new Error('Non-string result received while reading recipe photo'));
      }
    };
    reader.onerror = error => observer.error(error);
  });
}

export function downloadUrl(url: string) {
  const link = document.createElement('a');
  link.download = '';
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
