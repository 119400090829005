import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  Allergen,
  AllGastroRecipe,
  AnalyzeRecipeRequest,
  ApiService,
  BasicFood,
  CheckboxArrayOption, Component as Cp, ComponentNamePipe,
  CreateRecipeRequest, DEFAULT_COMPONENTS,
  DietDetails,
  DietNamePipe,
  FoodDietStatus,
  fromBitset,
  GetFilteredYieldGroupsRequest,
  GetYieldFactorRequest,
  GROUP_TYPE_TO_SIZES,
  GroupType,
  MealNamePipe,
  MealSize,
  SeasonNamePipe,
  SIZE_TO_GROUP_TYPE,
  SubscriptionSink, toBase64,
  toBitset,
  COMPONENT_DEFAULTS,
  UpdateRecipeRequest,
  validateFormArrayMinLength, ErrorComponent
} from '@app/shared';
import { AllergenNamePipe } from '@app/shared/allergen-name.pipe';
import { checkboxTitleComparator } from '@app/shared/checkbox-array/checkbox-array.component';
//import { CookingMethodNamePipe } from '@app/shared/cooking-method-name.pipe';
import { GastroNamePipe } from '@app/shared/gastro-name.pipe';
import { merge, NEVER, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {get, range} from 'lodash-es';

@Component({
  selector: 'app-recipe-form',
  templateUrl: './recipe-form.component.html',
  styleUrls: ['./recipe-form.component.scss']
})
export class RecipeFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
  COMPONENT_DEFAULTS = COMPONENT_DEFAULTS;

  allergenOptions: CheckboxArrayOption[];

  dietOptions: CheckboxArrayOption[];

  gastroOptions: CheckboxArrayOption[];

  cookingOptions: CheckboxArrayOption[] = [];

  yieldGroupOptions: CheckboxArrayOption[] = [];

  isYieldGroupDisabled: boolean = true;

  specifiedValues: FormArray;

  @ViewChild('file', {static: true})
  fileRef: ElementRef<HTMLInputElement>;

  @ViewChild(ErrorComponent, {static: true})
  errorComponent: ErrorComponent;

  @ViewChild('preparationElement', {static: true})
  preparationEl: ElementRef<HTMLTextAreaElement>;

  @Input()
  yearId: number;

  // Change this so it is not any - regenerate api-abstract-service.ts in cast-iron
  @Input()
  //recipe: Omit<Recipe, 'id' | 'yearId'> & {id?: number};
  recipe: any;

  @Input()
  sizes: MealSize[];

  @Input()
  customDiets: DietDetails[];

  dietExpanded: boolean[];

  @Output()
  save = new EventEmitter<Omit<CreateRecipeRequest, 'yearId'>>();

  @Output()
  dirty = new EventEmitter<boolean>();

  form: FormGroup;

  ingredients: FormArray;

  foods: {[k: number]: BasicFood} = {};

  seasonOptions: any[];

  mealOptions: any[];

  photoUrl: SafeUrl | null;

  photoBytes: string;

  portionsEstimations: {
    groupType: GroupType,
    sizes: ({
      size: MealSize;
      weight: number;
      count: number;
    } | null)[];
  }[];

  allergens: Allergen[];

  readonly search: (query: string) => Observable<any[]>;

  protected subscription = new SubscriptionSink();

  constructor(
      protected fb: FormBuilder,
      protected api: ApiService,
      dietNamePipe: DietNamePipe,
      seasonNamePipe: SeasonNamePipe,
      mealNamePipe: MealNamePipe,
      allergenPipe: AllergenNamePipe,
      gastroName: GastroNamePipe,
      //cookingName: CookingMethodNamePipe,
      protected domSanitizer: DomSanitizer,
      protected componentName: ComponentNamePipe
  ) {
    this.allergenOptions = allergenPipe.options();
    this.seasonOptions = seasonNamePipe.options;
    this.mealOptions = mealNamePipe.longOptions;
    //this.cookingOptions = cookingName.options;

    this.gastroOptions = AllGastroRecipe.map(code => ({
      title: gastroName.transform(code),
      value: code
    })).sort(checkboxTitleComparator);

    this.search = (query: string) => {
      if (!query) {
        return of([]);
      }
      return this.api
        .foodFindFood({ query, yearId: this.yearId, excludeFoodId: this.recipe?.id, includeIngredients: true })
        .pipe(map(({ foods }) =>
          foods.filter(food =>
            !this.form.value.ingredients.find(ingredient => ingredient.id === food.id)
          ))
        );
    };

    this.dietOptions = dietNamePipe.options;
  }

  protected validateValues(group: FormArray) {
    const chot =  group.value.find((c: any) => c.component === Cp.TotalCarbohydrates)?.value ?? 0;
    const prot =  group.value.find((c: any) => c.component === Cp.Protein)?.value ?? 0;
    const fat =  group.value.find((c: any) => c.component === Cp.Fat)?.value ?? 0;
    const satFattyAcids =  group.value.find((c: any) => c.component === Cp.SaturatedFattyAcids)?.value ?? 0;

    const errors: any = {};

    if (chot + prot + fat > 100) {
      errors.macroSum = true;
    }

    if (satFattyAcids > fat) {
      errors.satFattyAcids = true;
    }

    return errors;
  }

  protected createForm() {
    if (this.form) {
      return;
    }
    this.specifiedValues = this.fb.array(DEFAULT_COMPONENTS.map(component =>
      this.fb.group({
        component: [component],
        value: [null, [Validators.min(0)]]
      })
    ), {
      //validators: this.validateValues.bind(this)
    });
    this.ingredients = this.fb.array([], validateFormArrayMinLength(1));

    this.form = this.fb.group({
      name: [null, Validators.required],
      ingredients: this.ingredients,
      cookingMethod: [null, [Validators.required]],
      yieldGroup: [{value: null}],
      yieldFactor: [null, [Validators.required, Validators.min(0.25)]],
      seasonQuality: [],
      gastro: [{value: null}, Validators.required],
      edible: [null, [Validators.min(0), Validators.max(100)]],
      preparation: [],
      seq: [],
      designation: [],
      source: [],
      author: [],
      customDiets: this.fb.array(range(this.customDiets.length).map(() => [])),
      specifiedValues: this.specifiedValues,
    });

    let yieldGroupProgramaticChange = false;

    // If prep method gets chosen or changes, fetch possible yield groups
    this.subscription.sink = merge(
      this.form.get('cookingMethod').valueChanges,
    )
    .pipe(
      switchMap(() => {
        const prepMethodId = this.form.get('cookingMethod').value;

        if (prepMethodId === undefined || !this.form.get('cookingMethod').dirty) {
          return NEVER;
        }

        if (prepMethodId === null) {
          this.yieldGroupOptions = []
          this.isYieldGroupDisabled = true
          return NEVER;
        }

        const request: GetFilteredYieldGroupsRequest = {
          prepMethodId: prepMethodId,
        };
        return this.api.foodGetFilteredYieldGroups(request);
      }),
    )
    .subscribe(rv => {
      this.yieldGroupOptions = []
      rv.yieldGroups.forEach(yieldGroup => {
        this.yieldGroupOptions.push({value: yieldGroup.code, title: yieldGroup.ingredient_slo})
      });

      if (rv.yieldGroups.length > 0) {
        this.isYieldGroupDisabled = false
      } else {
        this.isYieldGroupDisabled = true
        this.yieldGroupOptions.sort(checkboxTitleComparator);
      }

    });

    // If prep method or yield group get changed, try to fetch yield factor from theri combination
    this.subscription.sink = merge(
      this.form.get('cookingMethod').valueChanges,
      this.form.get('yieldGroup').valueChanges,
    )
    .pipe(
      debounceTime(250),
      switchMap(() => {
        // If yieldGroup was changed programatically, do nothing
        if (yieldGroupProgramaticChange) {
          yieldGroupProgramaticChange = false;
          return NEVER;
        }

        // If yieldGroup is not in the options, reset it
        const yieldGroupValue = this.form.get('yieldGroup').value;
        const isInYieldGroupOptions = this.yieldGroupOptions.some(option => 'value' in option && option.value === yieldGroupValue);
        if (yieldGroupValue !== null && !isInYieldGroupOptions) {
          this.form.value.yieldGroup = null;
          this.form.get('yieldGroup').setValue(null);
        }

        const value = this.form.value;

        // If cooking method is not defined or both cooking method and yield group are not dirty, do nothing
        if (value.cookingMethod === undefined || value.cookingMethod == null || (!this.form.get('cookingMethod').dirty && !this.form.get('yieldGroup').dirty)) {
          return NEVER;
        }

        // Set yield factor to default value based on cooking method if yield group is not defined
        if (value.yieldGroup === null) {
          if (value.cookingMethod == 87 && value.yieldFactor != 1){
            this.form.get('yieldFactor').setValue(1);
          } else if (value.cookingMethod != 87 && value.yieldFactor != 0.9) {
            this.form.get('yieldFactor').setValue(0.9);
          }
          return NEVER;
        }

        // Fetch yield factor based on cooking method and yield group
        const request: GetYieldFactorRequest = {
          yieldGroupCode: value.yieldGroup,
          prepMethodId: value.cookingMethod,
        };
        return this.api.foodGetYieldFactor(request);
      }),
    )
    .subscribe(rv => {
      if (rv.found) {
        yieldGroupProgramaticChange = true;
        this.form.get('yieldFactor').setValue(rv.yieldFactor);
      }
    });

    // If yield factor gets changed by the user, unselect the yieldGroup
    this.form.get('yieldFactor').valueChanges.subscribe(_ => {
      if (this.form.get('yieldFactor').dirty && !yieldGroupProgramaticChange && this.form.get('yieldGroup').value !== null) {
        this.form.get('yieldGroup').setValue(null);
        yieldGroupProgramaticChange = true;
      } else {
        yieldGroupProgramaticChange = false;
      }
    });

    // If yield factor, ingredient values or gastro changes, recalculate the recipe portion estimations
    this.subscription.sink = merge(
        this.form.get('yieldFactor').valueChanges,
        this.ingredients.valueChanges,
        this.form.get('gastro').valueChanges,
      )
      .pipe(
        debounceTime(250),
        switchMap(() => {
          const value = this.form.value;
          if (value.ingredients.length === 0) {
            this.allergens = [];
            this.portionsEstimations = [];
            return NEVER;
          }
          const request: AnalyzeRecipeRequest = {
            yearId: this.yearId,
            cookingMethod: value.cookingMethod,
            ingredients: value.ingredients.map(({id, weight}) => ({
              food: id,
              weight,
              onlyEdible: false,
              wholeWheat: false,
              soaked: false
            })),
            gastro: value.gastro,
            yieldFactor: value.yieldFactor
          };
          return this.api.foodAnalyzeRecipe(request);
        }),
      )
      .subscribe(rv => {
        const groupTypes: GroupType[] = [];
        rv.portions.forEach(p => {
          const groupType = SIZE_TO_GROUP_TYPE.get(p.size);
          if (!groupTypes.includes(groupType)) {
            groupTypes.push(groupType);
          }
        });
        groupTypes.sort();
        // yieldFactorError
        let error = ""
        if(this.form.get('yieldFactor').value < 0.25){
          error = "Faktor donosa mora biti večji ali enak 0,25";
        }
        this.errorComponent.error = error
          this.portionsEstimations = groupTypes.map(groupType => {
          const portion = {
            groupType: groupType,
              sizes: GROUP_TYPE_TO_SIZES.get(groupType).map(size => {
                const weight = rv.portions.find(e => e.size === size)?.weight ?? 0;
                if (!weight) {
                  return null;
                }

                const count = rv.finalWeight / weight;
                return {
                  size,
                  weight,
                  count,
                };
              })
          }
          portion.sizes = portion.sizes.concat(Array.from(Array(3 - portion.sizes.length)).map(() => null));
          return portion;
        });

        this.allergens = rv.allergens;
      });

    this.subscription.sink = this.form.statusChanges.subscribe(() => {
      this.dirty.emit(this.form.dirty);
    });
  }

  protected createIngredientGroup() {
    return this.fb.group({
      id: [null, Validators.required],
      weight: [null, [Validators.required, Validators.min(0.1)]]
    });
  }

  mapToForm(value: {component: Cp, value: number}) {
    const factor = COMPONENT_DEFAULTS.get(value.component).factor;
    if (value.value !== null) {
      value.value *= factor;
    }
    if (typeof(value.value) === 'number' && isFinite(value.value)) {
      if(value.component === Cp.Sodium){
        value.value = Number(Number(value.value).toFixed(5));
      }else {
        value.value = Number(Number(value.value).toFixed(1));
      }
    }
    return value;
  }

  ngOnInit() {
    this.createForm();

    this.fileRef.nativeElement.addEventListener('change', event => {
      const fileList: FileList = (event.target as any).files;
      if (fileList.length === 0) {
        return;
      }
      return toBase64(fileList[0]).subscribe(content => {
          this.photoUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(fileList[0]));
          this.photoBytes = content;
      }, () => {}, () => this.fileRef.nativeElement.value = '');
    });
  }

  ngOnChanges() {
    this.createForm();

    const nIngredients = this.recipe && this.recipe.ingredients ? this.recipe.ingredients.length : 0;

    while (this.ingredients.length > nIngredients) {
      this.ingredients.removeAt(0);
    }

    while (this.ingredients.length < nIngredients) {
      this.ingredients.push(this.createIngredientGroup());
    }

    this.photoUrl = null;

    this.photoBytes = null;

    this.dietExpanded = this.customDiets.map(() => false);

    if (this.recipe) {
      if (this.cookingOptions.length === 0) {
        this.recipe.prepMethods.forEach(prepMethod => {
          this.cookingOptions.push({value: prepMethod.id, title: prepMethod.DESCRIPTOR})
        });
        this.cookingOptions.sort(checkboxTitleComparator);
      }

      this.yieldGroupOptions = []
      this.recipe.yieldGroups.forEach(yieldGroup => {
        this.yieldGroupOptions.push({value: yieldGroup.code, title: yieldGroup.ingredient_slo})
      });
      this.yieldGroupOptions.sort(checkboxTitleComparator);

      this.isYieldGroupDisabled = this.recipe.yieldGroups.length <= 0;

      // Since we are now filtering prep methods, we need to check if the current cooking method is still available, if not, reset it
      if (this.recipe.cookingMethod && !this.recipe.prepMethods.find(prepMethod => prepMethod.id === this.recipe.cookingMethod)) {
        this.recipe.cookingMethod = null;
      }

      const ingredients = this.recipe.ingredients || [];
      this.form.setValue({
        name: this.recipe.name,
        ingredients: ingredients.map(ingredient => ({
          id: ingredient.food,
          weight: ingredient.weight
        })),
        cookingMethod: this.recipe.cookingMethod === 0 ? null : this.recipe.cookingMethod,
        yieldGroup: this.recipe.yieldGroup || null,
        yieldFactor: this.recipe.yieldFactor || null,
        seasonQuality: fromBitset(this.recipe.seasonQuality),
        gastro: this.recipe.gastro,
        edible: this.recipe.edible * 100,
        preparation: this.recipe.preparation || '',
        customDiets: this.customDiets.map((diet) => this.recipe.diets?.find(dietStatus => dietStatus.dietId === diet.id)?.approved ?? null),
        seq: this.recipe.seq ?? null,
        specifiedValues: DEFAULT_COMPONENTS.map(component => ({
          component,
          value: get(this.recipe.values.find(([c]) => c === component), 1, null)
        })).map(this.mapToForm.bind(this)),
        designation: this.recipe.designation ?? '',
        source: this.recipe.source ?? '',
        author: this.recipe.author ?? '',
      });
      this.subscription.sink = this.api
        .foodFindFood({ yearId: this.yearId, ids: ingredients.map(ingredient => ingredient.food), includeIngredients: true})
        .subscribe(rsp => this.foods = rsp.foods.reduce((c, food) => {
          c[food.id] = food;
          return c;
        }, {}));

      if (this.recipe.photos?.length > 0) {
        const photo = this.recipe.photos[0];
        this.photoUrl = `${environment.photoUrl}/${this.recipe.id}-${photo.id}.${photo.type}`;
      }
      this.form.markAsPristine();
      this.dirty.emit(false);
    } else {
      this.form.reset();
    }

    this.form.markAsUntouched();
    this.form.markAsPristine();
    this.dirty.emit(false);
  }

  getComponentLabel(index: number) {
    const component = this.form.value.specifiedValues[index].component;
    if (COMPONENT_DEFAULTS.get(component).label) {
      return COMPONENT_DEFAULTS.get(component).label;
    }
    return this.componentName.transform(component);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  addIngredient(food: BasicFood) {
    if (this.form.value.ingredients.find(({id}) => id === food.id)) {
      return;
    }

    const group = this.createIngredientGroup();
    group.setValue({
      id: food.id,
      weight: 100
    });
    this.ingredients.push(group);
    this.foods[food.id] = food;
    this.dirty.emit(true);
  }

  removeIngredient(id) {
    const index = this.ingredients.value.findIndex(ingredient => ingredient.id === id);
    if (index !== -1) {
      this.ingredients.removeAt(index);
    }
    this.dirty.emit(true);
  }

  submit($event) {
    $event.preventDefault();

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const value = this.form.value;
    const dietStatuses: FoodDietStatus[] = [];
    this.customDiets.forEach((diet, index) => {
      if (value.customDiets[index] === null) {
        return;
      }
      dietStatuses.push({
        dietId: diet.id,
        approved: value.customDiets[index],
      });
    });

    const request: Omit<UpdateRecipeRequest, 'yearId' | 'id'> = {
      name: value.name,
      ingredients: value.ingredients.map(({id, weight}) => ({
        food: id,
        weight,
        onlyEdible: false,
        wholeWheat: false,
        soaked: false
      })),
      cookingMethod: value.cookingMethod,
      yieldGroup: value.yieldGroup,
      yieldFactor: value.yieldFactor,
      gastro: value.gastro,
      diets: dietStatuses,
      seasonQuality: toBitset(value.seasonQuality),
      specifiedWeight: 0,
      edible: value.edible / 100,
      preparation: value.preparation,
      seq: value.seq,
      designation: value.designation,
      source: value.source,
      author: value.author,
    };

    if (this.photoBytes) {
      request.newPhoto = this.photoBytes;
    } else if (this.recipe && !this.photoUrl) {
      request.removedPhoto = true;
    }

    this.save.emit(request);
  }

  removePhoto() {
    this.photoUrl = null;
  }

  expandDiet(idx: number) {
    this.dietExpanded[idx] = !this.dietExpanded[idx];
  }

  ngAfterViewChecked() {
    const natEl = this.preparationEl.nativeElement;
    natEl.style.height = 'auto';
    natEl.style.height = natEl.scrollHeight + 'px';
  }

  setEdible(value: number) {
    if (this.form.value.edible === value) {
      return;
    }
    this.form.get('edible').setValue(value);
    this.form.get('edible').markAsDirty()
    this.dirty.emit(true);
  }
}
